import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/app/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/app/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/app/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/app/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/app/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/app/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/app/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/app/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/app/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/app/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/app/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/app/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/bootstrap/dist/css/bootstrap.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/(app)/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/config/registry.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticationProvider"] */ "/app/src/providers/app/AuthenticationProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["EnvironmentProvider"] */ "/app/src/providers/app/EnvironmentProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/providers/app/FacebookSdkProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/providers/app/FirebaseProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FloatingWindowProvider"] */ "/app/src/providers/app/FloatingWindowProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalAppStateProvider"] */ "/app/src/providers/app/GlobalAppStateProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalConfigProvider"] */ "/app/src/providers/app/GlobalConfigProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TRPCReactProvider"] */ "/app/src/trpc/react.tsx");
